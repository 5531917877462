import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    Operation,
    ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
// eslint-disable-next-line import/no-cycle
import getValidToken from './getValidToken';
import { GRAPHQL_CLIENT_NAMES } from './constants';

const authEndpointLink = createHttpLink({
    uri: process.env.REACT_APP_AUTH_API_URL,
    credentials: 'omit',
});

const reputationManagementLink = createHttpLink({
    uri: process.env.REACT_APP_REPUTATION_MANAGEMENT_API_URL,
    credentials: 'omit',
});

function authEndpointSplitter(operation: Operation): boolean {
    return operation.getContext().clientName === GRAPHQL_CLIENT_NAMES.auth;
}

function reputationManagementSplitter(operation: Operation): boolean {
    return (
        operation.getContext().clientName ===
        GRAPHQL_CLIENT_NAMES.reputationManagement
    );
}

const defaultLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: 'omit',
});

const defaultOrAuthLink = ApolloLink.split(
    reputationManagementSplitter,
    reputationManagementLink,
    defaultLink,
);

const httpLink = ApolloLink.split(
    authEndpointSplitter,
    authEndpointLink,
    defaultOrAuthLink,
);

const authLink = setContext(async ({ variables }, { headers: prevHeaders }) => {
    const headers = {
        ...prevHeaders,
        'Apollo-Require-Preflight': 'true',
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const context: Record<string, any> = { headers };

    if (variables?.__noAuth === true) {
        return context;
    }

    const accessToken = await getValidToken();

    if (accessToken) {
        headers.authorization = `Bearer ${accessToken}`;
    }

    return context;
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
});

export default client;
